
<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2023-12-01 11:15:48
 * @LastEditTime: 2023-12-14 15:07:40
 * @FilePath: \awx-ui\src\pages\appManage\editApp\rightStep.vue
-->
<template>
  <div class="edit-app-page-tabs">
    <app-base-info-vue v-if="showPage === 'base'" :showPage="showPage" />
    <div v-else-if="showPage.includes('step')">
      <StepEdit :appDetail="appDetail" :showPage="showPage" />
    </div>
    <div class="edit-list" v-else>
      <div class="flex-bewteen-container" style="padding-right: 24px">
        <div class="add-btn">
          <a-button @click.stop="onRefresh" class="mgr10">
            刷新 
          </a-button>
          <a-button type="primary" @click.stop="_add" icon="plus">
            {{ _addText }}
          </a-button>
          <a-button class="mgl12" v-if="['opt', 'trigger'].includes(showPage)" style="width: 116px" type="primary" @click="importStep">
            <svg-icon style="margin-right: 8px;" class="btn-before-icon" icon-class="host-import"></svg-icon>{{ _importText }}
          </a-button>
        </div>
        <a-input-search v-model="keyword" class="search-btn" placeholder="请输入关键字查询" @search="onRefresh" />
      </div>
      <templateStep :ref="'templateStep'+showPage" :isComponet='true' :showPage="showPage" :optType="opType" />
    </div>
  </div>
</template>
<script>
import templateStep from '@/pages/templateStep/index.vue'
import appBaseInfoVue from './components/baseInfo.vue'
import StepEdit from './components/stepEdit.vue'
import ImportStepModal from '@/components/importStep/index.vue'

export default {
  components: {
    templateStep,
    appBaseInfoVue,
    StepEdit
  },
  provide() {
    return {
      refreshStepList: this.refreshStepList,
      _edit: this._edit
    }
  },
  data() {
    return {
      showPage: 'base',
      appDetail: undefined,
      keyword: ''
    };
  },
  watch: {
    '$route': {
      handler (val) {
        if (val.query.id && val.path.includes('app-edit')) {
          // this.onRefresh()
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    _addText () {
      let _addText = this.showPage === 'auth' ? '新建鉴权' : this.showPage === 'opt' ? '新建操作' : this.showPage === 'trigger' ? '新建触发器' : ''
      return _addText
    },
    _importText () {
      let _importText = this.showPage === 'opt' ? '导入操作' : this.showPage === 'trigger' ? '导入触发器' : ''
      return _importText
    },
    appId () {
      let appId = this.$route.query.id || ''
      return appId
    },
    opType () {
      let opType = this.showPage === 'auth' ? '2' : this.showPage === 'opt' ? '0' : this.showPage === 'trigger' ?  '1' : ''
      return opType
    }
  },
  mounted () {
    this.$EventBus.$on("renderRightPage", (type, appDetail, editFlag) => {
      this.renderRightPage(type, appDetail, editFlag);
    });
    this.$EventBus.$on("refreshStepList", (type) => {
      this.refreshStepList(type);
    });
    
  },
  methods: {
    // 导入步骤
    importStep () {
      const self = this;
      let width = 736;
      let title = "导入数据";
      let content = (
        <ImportStepModal
          showPage={this.showPage}
          appId={this.appId}
          opType={this.opType}
          callBack={(showPage) => {
            self.refreshStepList(showPage)
          }}
        />
      );
      this.$confirm({
        width: width,
        title: title,
        content: content,
        closable: true,
        icon: () => {
          return <div />;
        },
      });
      this.$emit("showPop", '');
    },
    renderRightPage (type, appDetail, editFlag) {
      this.showPage = type
      this.keyword = ''
      if (this.showPage !== 'base' && !this.showPage.includes('step')) {
        this.onRefresh()
      } 
      if (editFlag)  {
        if (editFlag === 'edit') {
          this.showPage = type
          this.appDetail = appDetail
        } else {
          this.showPage = type
          this.appDetail = undefined
        }
      } else {
        this.appDetail = appDetail
      }
      console.log('当前展示', this.showPage)
    },
    refreshStepList (showPage, data) {
      this.showPage = showPage.includes('-') ? showPage.split('-')[1] : showPage
      this.onRefresh()
      if (data) {
        this.$EventBus.$emit("refreshTreeList", data);
      }
    },
    onRefresh () {
      this.$nextTick(() => {
        const ref = 'templateStep' + this.showPage
        this.$refs[`${ref}`].onSearch(this.keyword)
      })
    },
    callback(key) {
      this.tabKey = key
      this.onRefresh()
    },
    backFlow () {
      this.$router.go(-1)
    },
    _add () {
      this.showPage = this.opType === '2' ? 'step-auth' : this.opType === '0' ? 'step-opt' : 'step-trigger'
      this.appDetail = undefined
    },
    _edit (detail) {
      this.showPage = this.opType === '2' ? 'step-auth' : this.opType === '0' ? 'step-opt' : 'step-trigger'
      this.appDetail = detail
      this.$EventBus.$emit("setSelectNode", detail);
    }
  },
  beforeDestroy () {
    this.$EventBus.$off("renderRightPage");
    this.$EventBus.$off("refreshStepList");
  }
};
</script>
<style lang="less" scoped>
.edit-app-page-tabs {
  position: relative;
  .flow-back {
    cursor: pointer;
    &-icon {
      font-size: 20px;
      color: #333;
    }
  }
  .search-btn {
      width: 460px;
    }
  .task-steps-name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    color: #1d202d;
    font-weight: 600;
    padding: 0px 0 0 24px;
    height: 47px;
    padding-right: 16px;
    line-height: 47px;
    background: linear-gradient(
      to right,
      rgba(226, 235, 250, 0.4),
      rgba(240, 243, 247, 0.4)
    );
    border-radius: 6px 6px 0 0;
    border-bottom: 1px solid #dee8f5;
    &-edit {
      cursor: pointer;
    }
    .task-steps-name-edit {
      color: @primary-color;
      font-size: 16px;
    }
  }
  .app-tabs {
    padding: 0 0 0 24px;
    max-width: 1614px;
  }
  .add-btn {
    margin-bottom: 12px;
  }
  .edit-list {
    padding: 16px 0 16px 24px;
  }
}
</style>