<template>
  <div>
    <modifyStepVue :appDetail="appDetail" :showPage="showPage"/>
  </div>
</template>

<script>
import modifyStepVue from '@/pages/templateStep/modifyStep.vue'
export default {
  data () {
    return {
    }
  },
  props: {
    appDetail: Object,
    showPage: String
  },
  components: {
    modifyStepVue
  },
  mounted() {
  },
  methods: {
  },
}

</script>
<style scoped lang='less'>
</style>